import React from "react"
import Layout from "../../components/layout"


const Partner = () => (
  <Layout>
      <section className="section-padding-large biq-bg-prim biq-text-white">
          <div className="container">
              <h1>Partner</h1> 
          </div>
      </section>
  </Layout>
)

export default Partner